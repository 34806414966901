import { render, staticRenderFns } from "./Dashboard-new-design-dados.vue?vue&type=template&id=1366da34"
import script from "./Dashboard-new-design-dados.vue?vue&type=script&lang=js"
export * from "./Dashboard-new-design-dados.vue?vue&type=script&lang=js"
import style0 from "./Dashboard-new-design-dados.vue?vue&type=style&index=0&id=1366da34&prod&lang=scss"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&external"
import style2 from "vue2-datepicker/index.css?vue&type=style&index=2&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports